
import {
    FaUserTie,
    FaGraduationCap,
    FaBrain,
    FaLaptopCode,
    FaPhoneAlt,
    FaSchool,
    FaHome,
    FaMapMarkerAlt,
    FaJava,
    FaPhp,
    FaHtml5,
    FaCss3Alt,
    FaSass,
    FaBootstrap,
    FaCpanel,
    FaAws,
    FaPython
} from "react-icons/fa";

import { BsFillBuildingsFill } from "react-icons/bs";
import { TbBrandFirebase } from "react-icons/tb";
import { DiNodejsSmall } from "react-icons/di";
import { BiLogoAndroid, BiLogoAngular, BiLogoFirebase, BiLogoFlutter, BiLogoGit, BiLogoJquery, BiLogoReact, BiLogoSpringBoot, BiSolidSchool } from "react-icons/bi";
import { SiAdobephotoshop, SiAdobexd, SiHeroku, SiJavascript, SiLunacy, SiMongodb, SiMysql, SiOracle } from "react-icons/si";
import { IoLogoLaravel } from "react-icons/io5";

import noPicture from '../assets/workprojectimages/no-pictures.png'
import circularlogocharityhat from '../assets/workprojectimages/circularlogocharityhat.png'
import ticketnow from '../assets/workprojectimages/ticket_now.png'
import propertymarket from '../assets/workprojectimages/property-market.png'
import Covac from '../assets/workprojectimages/covac_logo.png'
import RRviewlogo from '../assets/workprojectimages/RRviewlogo.png'
import Licensefinder from '../assets/workprojectimages/license-finder.png'
import QRpay from '../assets/workprojectimages/QRpay.png'

// Data for nav bar
export const navBarData = [
    { route: '/profile', icon: <FaUserTie />, linkTitle: 'Profile' },
    { route: '/education', icon: <FaGraduationCap />, linkTitle: 'Education' },
    { route: '/skills', icon: <FaBrain />, linkTitle: 'Skills' },
    { route: '/work', icon: <FaLaptopCode />, linkTitle: 'Work' },
    { route: '/contact', icon: <FaPhoneAlt />, linkTitle: 'Contact' },
]

// data for skills

export const skillsImageDataLanguage = [
    { image: <FaJava size={80} color="#4E7D9B" />, title: 'Java', content: ' System.out.println("Hello World")' },
    { image: <FaPhp size={80} color="#7377AD" />, title: 'Php', content: ' <?php echo "Php is fun ?>"' },
    { image: <SiJavascript size={80} color="#CEB331" />, title: 'JavaScript', content: 'Console.log("Hello World")' },
    { image: <FaPython size={80} color="#346F9F" />, title: 'Python', content: ' print("Hello World")' },
    { image: <FaHtml5 size={80} color="#F16524" />, title: 'HTML', content: ' <h1> Hello World </h1>' },
    { image: <FaCss3Alt size={80} color="#2862E9" />, title: 'CSS', content: ' font-color: #23A9F2' },
    { image: <FaSass size={80} color="#C96195" />, title: 'SASS', content: '$bgcolor: lightblue;' },
   
]


export const skillsImageDataBackendFramework = [
    { image: <BiLogoSpringBoot size={80} color="#6AAD3D" />, title: 'Spring boot', content: ` @RequestMapping(value = "Skills", method = RequestMethod.GET)` },
    { image: <DiNodejsSmall size={80} color="#3F843C" />, title: 'Node js', content: ` var express = require(' + 'express);` },
    { image: <IoLogoLaravel size={80} color="#EC2A1E" />, title: 'Laravel', content: `Route::get(' /profile' + 'ProfileController::class)` },

]

export const skillsImageDataFrontendFramework = [
    { image: <BiLogoAngular size={80} color="#C3002F" />, title: 'Angular', content: `import { Injectable } from @angular/core ` },
    { image: <BiLogoReact size={80} color="#04E2E3" />, title: 'React js', content: ` const [count, setCount] = useState(0);`, },
    { image: <BiLogoJquery size={80} color="#1063A4" />, title: 'Jquery', content: '$("#skills").show()' },
    { image: <FaBootstrap size={80} color="#8811F1" />, title: 'Bootstrap', content: '<div class="col-sm"> This is last column </div>' },

]

export const skillsImageDataDatabase = [
    { image: <SiOracle size={80} color="#C34331" />, title: 'Oracle', content: `SELECT Column_Name1, Column_Name2, Column_Name FROM Table_Name WHERE Criteria;` },
    { image: <SiMysql size={80} color="#005E86" />, title: 'MySQL', content: `INSERT INTO employees (id, name, age, department) VALUES(1, 'Bibash kattel', 99, ICT` },
    { image: <SiMongodb size={80} color="#118D4D" />, title: 'Mongo DB', content: `db.COLLECTION_NAME.update(SELECTION_CRITERIA, UPDATED_DATA)` },

]

export const skillsImageDataMobileFramework = [
    { image: <BiLogoAndroid size={80} color="#30D780" />, title: 'Android', content: `SetContentView(R.layout.activity_main)` },
    { image: <BiLogoFlutter size={80} color="#51BFF0" />, title: 'Flutter', content: `Text('Hello world', style: TextStyle(fontSize:12) );`, },
    { image: <BiLogoFirebase size={80} color="#F7C52A" />, title: 'Firebase', content: `final FirebaseDatabase database = FirebaseDatabase.getInstance();` },

]

export const skillsImageDataHosting = [
    { image: <FaCpanel size={80} color="#EB6932" />, title: 'Cpanel', content: 'Hosting Node JS, Php, Laravel application' },
    { image: <FaAws size={80} color="#F79400" />, title: 'AWS hosting', content: ' Hosting backend application like Node js', },
    { image: <TbBrandFirebase size={80} color="#ED7E0B" />, title: 'Firebase hosting', content: 'Hosting single page application like Angular and React JS apps' },
    { image: <SiHeroku size={80} color="#6311AA" />, title: 'Heroku hosting', content: 'Hosting mostly Node JS application' },

]

export const skillsImageDataOtherSkills = [
    { image: <SiAdobephotoshop size={80} color="#2FA3F7" />, title: 'Photoshop', content: 'Designing graphics, editing photos' },
    { image: <SiAdobexd size={80} color="#F75EEE" />, title: 'Adoxe XD ', content: ' Designing web and mobile user interfaces', },
    { image: <SiLunacy size={80} color="#1698DC" />, title: 'Lunacy', content: 'Designing web and mobile user interfaces' },
    { image: <BiLogoGit size={80} color="#E84D31" />, title: 'Git', content: 'anaging code and version control in GitLab, BitBucket, GitHub' },

]




export const workProjectSDetailsWeb = [
    { image: circularlogocharityhat, title: 'Charity Hat', language: 'Angular, Node JS, MongoDB', content: 'The web platform serves as a medium for users to donate their old and unused items to those in need. Users can post the items they wish to donate, and these postings undergo verification by the admin. Needy individuals can then make requests to pick up the listed items directly from the webpage.' },
    { image: ticketnow, title: 'Ticket Now', language: 'React, Springboot, MySQL', content: 'The website provides a platform for users to browse and view the available tickets for upcoming movies. However, the actual booking of tickets can only be done through the dedicated mobile application provided' },
    { image: propertymarket, title: 'Property Market', language: 'Laravel, MySQL', content: 'This website serves as a platform for users to explore locally available properties, including houses, lands, flats, and apartments. Users can access detailed information about each property, such as price, land area, and location. Additionally, the website allows users to request a viewing of the properties, with the administration handling the booking process.' },
    { image: noPicture, title: 'Office attendance system', language: 'Laravel, MySQL', content: 'The web application is an office attendance system developed for the organization where I was employed. It enables staff members to mark their attendance by signing in and out during their office hours' },
    { image: noPicture, title: 'Eventmandu', language: 'React, Node JS, MongoDB', content: 'The website offers a convenient platform for users to book a wide range of event services, such as marriage functions, receptions, concerts, and more. Users have the flexibility to select their preferred venue from the locally available options listed on the website.' },
    { image: noPicture, title: 'IOT Home system control panel ', language: 'Angular, Firebase', content: 'The web application serves as a control panel for an Internet of Things (IoT) system. This project was developed as part of a college project at Softwarica College of IT and E-Commerce.' },

]

export const workProjectSDetailsMobile = [
    { image: QRpay, title: 'QRpay ', language: 'Flutter, Node JS, Mongo DB', content: 'The application is a cross-platform mockup designed to showcase the functioning prototype of a QR payment system. It was developed as part of my MSc. computer science dissertation titled "Implementation of fintech digital QR payment in merchant service." For this outstanding work, I received the Course Tutor Award for being the best-performing student in the course.' },
    { image: ticketnow, title: 'Ticket Now', language: 'Android (Java), Node JS, MYSQL', content: 'The extended Android application complements the Ticket Now website by providing users with the convenience of booking upcoming movie tickets directly from their mobile devices.' },
    { image: RRviewlogo, title: 'RRview', language: 'Flutter, Node JS, MongoDB', content: 'The cross-platform mobile application enables users to submit feedback and reviews for restaurants in their local area. Users can conveniently access the restaurants food menu and view its location on the map directly from the app.' },
    { image: Covac, title: 'Covac', language: 'Flutter, Node JS, MongoDB', content: 'The cross-platform mobile application facilitates the booking of appointments for the COVID-19 vaccine. Users can easily schedule their vaccination appointments and view the clinic locations on the map, along with available time slots. The app also allows users to modify the appointment date and time as needed, and they can cancel the appointment before the scheduled day.' },
    { image: Licensefinder, title: 'License finder app', language: 'Android (Java),Spring boot, MySQL', content: 'IThe Android application is designed to enable users to access and check their driving license results. Users can easily search and find their driving license status from a list of published results within the app..' },
    { image: noPicture, title: 'Weight tracker app', language: 'Android (Java), Spring boot, MySQL', content: 'The Android application is a comprehensive tool that allows users to monitor their weight progress. It enables users to record daily activities, track calorie consumption and expenditure, log their food intake, and generate detailed reports for analyzing their weight growth or loss over time.' },

]



// \end of data for skills


// Data for education
export const educationTimeLineData = [
    {
        title: 'Msc. Computer Science', school: 'Coventry University, Prioriry Street Coventry , CV1 5FB', grade: 'Distinction (68.3%)', passedYear: '2022', details: '1 year full time post graduate course on main campus coventry UK. Thesis on " Implementation of QR code payment system in merchant services "'
        , color: '#269746',
        icon: <FaGraduationCap />,
        awarsList: [
            { title: 'Course Tutor Award - Best performing student' },
            { title: 'Masters of science with distinction in computer science' },

        ],
        CourseList: [
            { title: 'Machine learning and big data ' },
            { title: 'Computer architecture and system programming ' },
            { title: 'Artificial neural networks ' },
            { title: 'Mobile platforms and application development  ' },
            { title: 'Web application and AI ' },
            { title: 'Software development project ' },
            { title: 'Security of emerging of connected systems' },
        ],


    },

    {
        title: 'Bsc. Hons in computing', school: 'Softwarica Collage of IT & E-Commerce, Dillibazar, Kathmandu Nepal', grade: 'First class (77.3%)', passedYear: '2016 - 2019', details: '3 year full time undergraduate course on Softwarica Collage of IT & E-Commerce affiliated to Coventry University UK. Thesis on " Comprehensive study on various data management techniques and application of appropriate technique in Property Market Real State System"'
        , color: '#327EEF',
        icon: <BiSolidSchool />,
        awarsList: [
            { title: 'Bachelor of science with honors in computing' }
        ],
        CourseList: [
            { title: 'Android application development ' },
            { title: 'Agile development ' },
            { title: 'Interactive pervasive computing' },
            { title: 'Web API development ' },
            { title: 'Academic Writing ' },
            { title: 'Individual Project ' },
        ],
    },


    {
        title: '+2 Higher Education ', school: 'Arniko Awasiya Higher Secondary School, Biratnagar, Morang Nepal', grade: 'Distinction  (76.2%)', passedYear: '2014 - 2016', details: '2 year full time higher education course on Arniko Awasiya Higher Secondary School. This degree is also known as +2 or higher education collage degree.'
        , color: '#FF7424',
        icon: <FaSchool />,
        awarsList: [

        ],
        CourseList: [
            { title: 'Physics' },
            { title: 'Chemistry ' },
            { title: 'Mathematics' },
            { title: 'Computer Science ' },
            { title: 'Nepali' },
            { title: 'English ' },
        ],
    },

    {
        title: 'Grade 10 - SLC (School Leaving Certificate) ', school: 'St. Joseph Higher Secondary School, Biratnagar, Morang, Nepal', grade: 'Distinction  (81.5%)', passedYear: '2013', details: 'This full-time school course spans a duration of 10 to 12 years, from Nursery to Grade 10, and upon completion, it is recognized as fulfilling the requirements for secondary school education'
        , color: '#E43C2F',
        icon: <FaHome />,
        awarsList: [

        ],
        CourseList: [
            { title: 'Social Studies' },
            { title: 'Chemistry ' },
            { title: 'Mathematics' },
            { title: 'Computer Science ' },
            { title: 'Health, Population & Environmen Education ' },
            { title: 'Science ' },
            { title: 'Nepali' },
            { title: 'English ' },
        ],
    },

]

// data for current work
export const workDetails = [
    { title: 'ICT Senior Mobile Application Development Engineer', icon: <FaUserTie />, tootltip: 'Current Position' },
    { title: 'Great Ormond Street Hospital for Children', icon: <BsFillBuildingsFill />, tootltip: 'Organization' },
    { title: 'Great Ormond Street, Central London WC1N 3JH, United Kingdom', icon: <FaMapMarkerAlt />, tootltip: 'Location' },
    { title: 'Full Stack Developer /  Mobile Developer (3.5 Years)', icon: <FaLaptopCode />, tootltip: 'Experience' },
]

// data for work
export const otherExperience = [
    { title: 'Hosting websites, Single page application and API in different platforms like firebase, AWS, Heroku and cPanel' },
    { title: 'Hands on experience working with IOT devices and sensors like RFID, pressure sensor, temperature sensor, Wi-Fi sensors and micro controllers.' },
    { title: 'Data analyst for HUDL and Expensify, freelancing projects for consultant agency, websites for local hotels and travel agencies' },
    { title: 'Certification course for Laravel, Advance Java, Enterprise Oracle 11g and data visualization with with business intelligence tools like tableau ' },
    { title: 'Thesis on “Data management technique and its application in different business sector” ' },
    { title: 'Thesis on “Implementation of fintech digital QR payment in merchant service” ' },
]