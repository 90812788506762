/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import "../css/skills.css";

import {
  skillsImageDataBackendFramework,
  skillsImageDataDatabase,
  skillsImageDataFrontendFramework,
  skillsImageDataHosting,
  skillsImageDataLanguage,
  skillsImageDataMobileFramework,
  skillsImageDataOtherSkills,
} from "../utils/staticData";
import {
  FaDatabase,
  FaGhost,
  FaGlobe,
  FaInfoCircle,
  FaLanguage,
  FaMobileAlt,
  FaServer,
} from "react-icons/fa";

const Skills = () => {

  let skillsHeadingText =
  "I possess a diverse skill set that encompasses both web and mobile development, and I have consistently worked towards improving and aligning my abilities with industry standards. My expertise primarily lies in backend development, where I excel in working with Node.js, Java, and Laravel (Php). Additionally, I have hands-on experience as a front-end developer, proficient in utilizing popular frameworks like Angular and React for web development. Furthermore, I have a strong background in mobile development, proficient in Core Android and Flutter. In addition to my development skills, I am well-versed in designing attractive and contemporary web and mobile user interfaces. Moreover, I have demonstrated proficiency in deploying applications on various hosting platforms such as AWS, CPanel, Heroku, and Firebase Hosting. My continuous efforts in honing my skills and staying up-to-date with the latest technologies allow me to deliver exceptional and innovative solutions in the field of software development"

  useEffect(() => {
    document.title = "SKILLS | BIBASH KATTEL";
  }, []);

return(
      <div
        className="skills-wrapper"
        data-aos="fade-down"
        data-aos-duration="500"
      >
        <div className="skills-heading-container">
          <div className="skills-heading-text">
            <span>Skills</span>
          </div>
          <div
            className="skills-heading-content"
            data-aos="fade-down"
            data-aos-duration="800"
          >
            <p>{skillsHeadingText}</p>
          </div>
        </div>
        <div
          className="skills-gallery-container"
          data-aos="fade-right"
          data-aos-duration="500"
        >
          {/* Skills category for programming langauge */}
          <div className="skills-category">
            <div className="skills-category-header">
              <span style={{ fontSize: "15px" }}>
                {" "}
                <FaLanguage size={20} /> Programming languages{" "}
              </span>{" "}
              <br /> <br />
            </div>
            <div className="skills-category-gallery">
              {skillsImageDataLanguage.map((data, index) => {
                return (
                  <div
                    className="flip-box"
                    key={index}
                    data-aos="fade-right"
                    data-aos-duration={500 * index}
                  >
                    <div className="flip-box-inner">
                      <div className="flip-box-front">
                        <span style={{ marginTop: "5px" }}> {data.image}</span>
                        <span>{data.title}</span>
                      </div>
                      <div className="flip-box-back">
                        <span>{data.content} </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Skills category for backend framework */}
          <div className="skills-category">
            <div className="skills-category-header">
              <span style={{ fontSize: "15px" }}>
                {" "}
                <FaServer size={20} /> Backend Framework{" "}
              </span>{" "}
              <br /> <br />
            </div>
            <div className="skills-category-gallery">
              {skillsImageDataBackendFramework.map((data, index) => {
                return (
                  <div
                    className="flip-box"
                    key={index}
                    data-aos="fade-right"
                    data-aos-duration={500 * index}
                  >
                    <div className="flip-box-inner">
                      <div className="flip-box-front">
                        <span style={{ marginTop: "5px" }}> {data.image}</span>
                        <span>{data.title}</span>
                      </div>
                      <div className="flip-box-back">
                        <span>{data.content} </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Skills category for front framework */}
          <div className="skills-category">
            <div className="skills-category-header">
              <span style={{ fontSize: "15px" }}>
                {" "}
                <FaGlobe size={20} /> Frontend Framework{" "}
              </span>{" "}
              <br /> <br />
            </div>
            <div className="skills-category-gallery">
              {skillsImageDataFrontendFramework.map((data, index) => {
                return (
                  <div
                    className="flip-box"
                    key={index}
                    data-aos="fade-right"
                    data-aos-duration={500 * index}
                  >
                    <div className="flip-box-inner">
                      <div className="flip-box-front">
                        <span style={{ marginTop: "5px" }}> {data.image}</span>
                        <span>{data.title}</span>
                      </div>
                      <div className="flip-box-back">
                        <span>{data.content} </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Skills category for mobile framework */}
          <div className="skills-category">
            <div className="skills-category-header">
              <span style={{ fontSize: "15px" }}>
                {" "}
                <FaMobileAlt size={20} /> Mobile Framework{" "}
              </span>{" "}
              <br /> <br />
            </div>
            <div className="skills-category-gallery">
              {skillsImageDataMobileFramework.map((data, index) => {
                return (
                  <div
                    className="flip-box"
                    key={index}
                    data-aos="fade-right"
                    data-aos-duration={500 * index}
                  >
                    <div className="flip-box-inner">
                      <div className="flip-box-front">
                        <span style={{ marginTop: "5px" }}> {data.image}</span>
                        <span>{data.title}</span>
                      </div>
                      <div className="flip-box-back">
                        <span>{data.content} </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Skills category for database */}
          <div className="skills-category">
            <div className="skills-category-header">
              <span style={{ fontSize: "15px" }}>
                {" "}
                <FaDatabase size={20} /> Database{" "}
              </span>{" "}
              <br /> <br />
            </div>
            <div className="skills-category-gallery">
              {skillsImageDataDatabase.map((data, index) => {
                return (
                  <div
                    className="flip-box"
                    key={index}
                    data-aos="fade-right"
                    data-aos-duration={500 * index}
                  >
                    <div className="flip-box-inner">
                      <div className="flip-box-front">
                        <span style={{ marginTop: "5px" }}> {data.image}</span>
                        <span>{data.title}</span>
                      </div>
                      <div className="flip-box-back">
                        <span>{data.content} </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Skills category for hosting */}
          <div className="skills-category">
            <div className="skills-category-header">
              <span style={{ fontSize: "15px" }}>
                {" "}
                <FaGhost size={20} /> Hosting{" "}
              </span>{" "}
              <br /> <br />
            </div>
            <div className="skills-category-gallery">
              {skillsImageDataHosting.map((data, index) => {
                return (
                  <div
                    className="flip-box"
                    key={index}
                    data-aos="fade-right"
                    data-aos-duration={500 * index}
                  >
                    <div className="flip-box-inner">
                      <div className="flip-box-front">
                        <span style={{ marginTop: "5px" }}> {data.image}</span>
                        <span>{data.title}</span>
                      </div>
                      <div className="flip-box-back">
                        <span>{data.content} </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Skills category for other skills*/}
          <div className="skills-category">
            <div className="skills-category-header">
              <span style={{ fontSize: "15px" }}>
                {" "}
                <FaInfoCircle size={20} /> Other skills{" "}
              </span>{" "}
              <br /> <br />
            </div>
            <div className="skills-category-gallery">
              {skillsImageDataOtherSkills.map((data, index) => {
                return (
                  <div
                    className="flip-box"
                    key={index}
                    data-aos="fade-right"
                    data-aos-duration={500 * index}
                  >
                    <div className="flip-box-inner">
                      <div className="flip-box-front">
                        <span style={{ marginTop: "5px" }}> {data.image}</span>
                        <span>{data.title}</span>
                      </div>
                      <div className="flip-box-back">
                        <span>{data.content} </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
};

export default Skills;
