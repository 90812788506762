import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Keyboard, Navigation, Pagination } from "swiper/modules";
import "../css/work.css";
import "swiper/css/navigation";

import {
  otherExperience,
  workDetails,
  workProjectSDetailsMobile,
  workProjectSDetailsWeb,
} from "../utils/staticData";
import {
  FaCircle,
  FaGlobe,
  FaMobile,
  FaPlay,
  FaProjectDiagram,
} from "react-icons/fa";
import { MdWorkHistory } from "react-icons/md";

import { Tooltip as ReactTooltip } from "react-tooltip";
const Work = () => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  useEffect(() => {
    document.title = "WORK |  BIBASH KATTEL";
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  return (
    <div className="work-wrapper" data-aos="fade-down" data-aos-duration="500">
      <div className="work-heading-container">
        <div className="work-heading-text">
          <span>Work</span>
        </div>
        <div
          className="work-heading-content"
          data-aos="fade-down"
          data-aos-duration="800"
        >
          <div className="work-details">
            {workDetails.map((workdata, index) => {
              return (
                <div
                  key={index}
                  data-aos="fade-down"
                  data-aos-duration={500 * index}
                >
                  <span
                    id={workdata.index + workdata.title}
                    className="work-data-list"
                  >
                    {" "}
                    {workdata.icon} {workdata.title}{" "}
                  </span>
                  <ReactTooltip
                    key={index}
                    anchorId={workdata.index + workdata.title}
                    place="top"
                    content={workdata.tootltip}
                    style={{
                      backgroundColor: "var(--primary-color)",
                      color: "#000000",
                      fontSize: "12px",
                      fontWeight: "lighter",
                    }}
                  />
                </div>
              );
            })}
            <span
              style={{
                fontWeight: "lighter",
                fontFamily: "var(--content-font-family)",
                fontStyle: "italic",
                color: "grey",
                fontSize: "12px",
              }}
            >
              Note: Work history and reference would be provided on request
            </span>
          </div>
        </div>
      </div>
      <div
        className="work-gallery-container"
        data-aos="fade-right"
        data-aos-duration="500"
      >
        <div style={{ marginBottom: "4%", color: "var(--primary-color)" }}>
          <FaProjectDiagram size={20} /> Some featured projects
        </div>
        {/* Work category for websites  */}
        <div className="work-category">
          <div className="work-category-header">
            <span style={{ fontSize: "15px" }}>
              <FaGlobe size={20} /> Web Development
            </span>
            <br /> <br />
          </div>
          {/* <div className="work-category-gallery"> */}
          <Swiper
            slidesPerView={screenSize.width >= 600 ? 3 : 1}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            // navigation={true}
            keyboard={{
              enabled: true,
            }}
            modules={[Pagination, Navigation, Keyboard]}
            className="mySwiper"
            breakpoints={{}}
            style={{
              "--swiper-pagination-color": "var(--primary-color)",
              "--swiper-pagination-bullet-inactive-color": "#999999",
              "--swiper-pagination-bullet-inactive-opacity": "1",
              "--swiper-pagination-bullet-size": "12px",
              "--swiper-pagination-bullet-horizontal-gap": "6px",
            }}
          >
            {workProjectSDetailsWeb.map((data, index) => {
              return (
                <SwiperSlide key={index} className="work-category-gallery">
                  <div
                    className="flip-box"
                    data-aos="fade-right"
                    data-aos-duration={500 * index}
                  >
                    <div className="flip-box-inner">
                      <div className="flip-box-front">
                        <img src={data.image} alt={data.title} width={80} />
                        <span>{data.title}</span>
                        <span style={{ fontSize: "10px" }}>
                          {" "}
                          <FaPlay size={10} /> {data.language}
                        </span>
                      </div>
                      <div className="flip-box-back">
                        <span>{data.content} </span>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {/* </div> */}
        </div>
        {/* End of work category for websites */}

        {/* Work category for mobile apps  */}
        <div className="work-category">
          <div className="work-category-header">
            <span style={{ fontSize: "15px" }}>
              <FaMobile size={20} /> Mobile Development
            </span>
            <br /> <br />
          </div>
          {/* <div className="work-category-gallery"> */}
          <Swiper
            slidesPerView={screenSize.width >= 600 ? 3 : 1}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            // navigation={true}
            keyboard={{
              enabled: true,
            }}
            modules={[Pagination, Navigation, Keyboard]}
            className="mySwiper"
            breakpoints={{}}
            style={{
              "--swiper-pagination-color": "var(--primary-color)",
              "--swiper-pagination-bullet-inactive-color": "#999999",
              "--swiper-pagination-bullet-inactive-opacity": "1",
              "--swiper-pagination-bullet-size": "12px",
              "--swiper-pagination-bullet-horizontal-gap": "6px",
            }}
          >
            {workProjectSDetailsMobile.map((data, index) => {
              return (
                <SwiperSlide key={index} className="work-category-gallery">
                  <div
                    className="flip-box"
                    data-aos="fade-right"
                    data-aos-duration={500 * index}
                  >
                    <div className="flip-box-inner">
                      <div className="flip-box-front">
                        <img src={data.image} alt={data.title} width={80} />
                        <span>{data.title}</span>
                        <span style={{ fontSize: "10px" }}>
                          <FaPlay size={10} /> {data.language}
                        </span>
                      </div>
                      <div className="flip-box-back">
                        <span>{data.content} </span>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {/* </div> */}
        </div>
        {/* End of work category for mobile apps */}


        {/* other work and experience */}
        <div style={{color: "var(--primary-color)" }}>
          <MdWorkHistory size={20} /> Other work and experience
        </div>
        <div
          className="work-heading-content"
          data-aos="fade-down"
          data-aos-duration="800"
        >
          <div className="work-details">
            {otherExperience.map((workdata, index) => {
              return (
                <div
                  key={index}
                  data-aos="fade-down"
                  data-aos-duration={500 * index}
                >
                  <span
                    id={workdata.index + workdata.title}
                    className="work-data-list"
                  >
                    <FaCircle size={8} /> {workdata.title}
                  </span>
                </div>
              );
            })}
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
