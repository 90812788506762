import React, { useEffect, useState } from "react";
import "../css/contact.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { MetroSpinner } from "react-spinners-kit";
const Contact = () => {
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("success");
  const [open, setOpen] = useState(true);



  const schema = yup.object({
    fname: yup
      .string()
      .required("Please enter your first name *")
      .matches(
        /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
        "Enter valid first name !"
      ),
    lname: yup
      .string()
      .required("Please enter your last name *")
      .matches(
        /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
        "Enter valid last name !"
      ),
    email: yup
      .string()
      .required("Please enter your email address  *")
      .matches(
        /^^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Enter valid email address !"
      ),
    message: yup.string().required("Please include your message *"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(()=>{
    document.title= "CONTACT | BIBASH KATTEL "
  }, [])

  const formSubmit = (data) => {
    postMessage(data);
  };

  const postMessage = async (messageData) => {
    let baseURL = "https://deltasolution.co/portfolioemail";
    setIsMessageSending(true);
    await axios
      .post(baseURL, messageData)
      .then((responsedata) => {
        if (responsedata.data.success) {
          setShowAlert(true);
          setIsMessageSending(false);
          setAlertType("success");
          setAlertMessage(responsedata.data.msg);
          setInterval(() => {
            window.location.reload();
          }, 3000);
        } else {
          setShowAlert(true);
          setIsMessageSending(true);
          setAlertType("error");
          setAlertMessage(responsedata.data.msg);
        }
      })
      .catch((error) => {
        setShowAlert(true);
        setAlertType("error");
        setIsMessageSending(false);
        setAlertMessage("Error connecting to server please try again later !");
      });
  };

  return (
    <div
      className="contact-wrapper"
      data-aos="fade-down"
      data-aos-duration="500"
    >
      <div className="contact-heading-container">
        <div className="contact-heading-text">
          <span>Contact</span>
        </div>

        {/* Alert box for informing user about message being send to the user */}
        {showAlert ? (
          <Box sx={{ width: "95%", marginLeft: "2%", marginRight: "2%" }}>
            <Collapse in={open}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
                severity={alertType}
              >
                {alertMessage}
              </Alert>
            </Collapse>
          </Box>
        ) : null}
      </div>

      <div className="contact-form-wrapper">
        <form className="contact-form" onSubmit={handleSubmit(formSubmit)}>
          <div className="input-container">
            <input
              type="text"
              className="inputs"
              placeholder="First name *"
              data-aos="fade-right"
              data-aos-duration="500"
              {...register("fname")}
            />
            <span className="validation-error-msg">
              {errors.fname?.message}
            </span>
            <input
              type="text"
              className="inputs"
              placeholder="Last name *"
              data-aos="fade-right"
              data-aos-duration="800"
              {...register("lname")}
            />
            <span className="validation-error-msg">
              {errors.lname?.message}
            </span>
            <input
              type="text"
              className="inputs"
              placeholder="Email *"
              data-aos="fade-right"
              data-aos-duration="1100"
              {...register("email")}
            />
            <span className="validation-error-msg">
              {errors.email?.message}
            </span>
            <textarea
              rows={5}
              className="inputs"
              placeholder="Your message *"
              data-aos="fade-right"
              data-aos-duration="1400"
              {...register("message")}
            />
            <span className="validation-error-msg">
              {errors.message?.message}
            </span>

            {isMessageSending ? (
              <div className="send-msg-loading-container">
                <MetroSpinner color="var(--primary-color)" size={45} />
              </div>
            ) : (
              <button
                className="btn-send-msg"
                data-aos="fade-right"
                data-aos-duration="1700"
              >
                Send
              </button>
            )}


            <span className="info-mobile-view">
              Please browse from your computer for live chat
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
