import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Contact from "./Contact";
import Education from "./Education";
import Profile from "./Profile";
import Skills from "./Skills";
import Work from "./Work";

const MainRoutes = () => {
  let routeList = [
    { path: "/profile", element: <Profile /> },
    { path: "/education", element: <Education /> },
    { path: "/skills", element: <Skills /> },
    { path: "/work", element: <Work /> },
    { path: "/contact", element: <Contact /> },
  ];
  return (
    <Routes>
      {routeList.map((routeData, index) => {
        return (
          <Route
            key={index}
            path={routeData.path}
            element={routeData.element}
          />
        );
      })}
      {/* redirecting file profile for base url */}
      <Route path="/" element={<Navigate to="/profile" />} />
    </Routes>
  );
};

export default MainRoutes;
