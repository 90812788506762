import React, { useEffect } from "react";
import "../css/education.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FaHashtag, FaStar } from "react-icons/fa";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { educationTimeLineData } from "../utils/staticData";



const Education = () => {

  useEffect (()=>{
    document.title= "EDUCATION | EDUCATION"
  }, [])
  return (
    <>
      <div
        className="education-wrapper"
        data-aos="fade-down"
        data-aos-duration="500"
      >
        <div className="education-heading-container">
          <div className="education-heading-text">
            <span>Education</span>
          </div>
        </div>
        <div
          className="education-gallery"
          data-aos="fade-down"
          data-aos-duration="800"
        >
          <VerticalTimeline>
            {educationTimeLineData.map((data, index) => {
              return (
                <VerticalTimelineElement
                  key={index}
                  contentStyle={{ background: `${data.color}`, color: "#fff" }}
                  contentArrowStyle={{
                    borderRight: `7px solid ${data.color}`,
                  }}
                  date={data.passedYear}
                  iconStyle={{ background: `${data.color}`, color: "#fff" }}
                  icon={data.icon}
                >
                  <h3 style={{ marginBottom: "5px" }}>{data.title}</h3>
                  <h5 style={{ marginBottom: "5px" }}>{data.school}</h5>
                  <h5 style={{ marginBottom: "15px" }}>{data.grade}</h5>

                  <p style={{ fontWeight: "lighter", fontSize: "11px" }}>
                    {data.details}
                  </p>

                  <br />

                  {/* lIST OF COURSES */}
                  <Accordion
                    sx={{
                      background: "none",
                      color: "white",
                      outline: "none",
                      boxShadow:
                        "2px 2px 2px  var(--darktheme-main-body-background-color)",
                      border: `1px solid ${data.color}`,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h4> Courses list </h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      {data.CourseList.map((data2, index2) => {
                        return (
                          <div key={index2}>
                            <h5
                              style={{
                                fontFamily: "var(--content-font-family)",
                              }}
                            >
                              {"-"} {data2.title}
                            </h5>
                            <br />
                          </div>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                  <br />

                  {/* AwarDS LIST */}
                  {data.awarsList.map((data3, index3) => {
                    return (
                      <div
                        key={index3}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <span
                          className="education-awards"
                          style={{ margin: "2%" }}
                        >
                          <FaHashtag />
                          {data3.title}
                        </span>
                      </div>
                    );
                  })}
                </VerticalTimelineElement>
              );
            })}

            <VerticalTimelineElement
              iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
              icon={<FaStar />}
            />
          </VerticalTimeline>
        </div>
      </div>
    </>
  );
};

export default Education;
