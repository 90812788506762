import React, { useEffect } from "react";
import "../css/profile.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { TypeAnimation } from "react-type-animation";
import profileImage from "../assets/profile-image.jpg";
import { Tooltip as ReactTooltip } from "react-tooltip";
const Profile = () => {
  const ProfileRole =
    "Frontend developer / Backend developer / Mobile developer / IOT enthusiast /  DevOps";

  let abouteMe =
    '"I am a highly motivated and dedicated software developer with 3.5 years of  experience and a postgraduate honors degree in computer science. My approach to work is characterized by a strong focus on quality, along with an analytical mindset that allows me to delve deep into projects. Through my leadership and management abilities, as well as my technical and analytical skills, I have consistently achieved success in delivering exceptional result. One of the keys to my success has been my commitment to personal growth, constantly developing my skills, knowledge, and expertise in the field of software development. Moreover, I take great pride in fostering the growth and development of my team members, as I believe in the value of nurturing a collaborative and supportive work environment \n Looking ahead, I am excited to continue my professional journey in software development, building upon my existing strengths and striving to contribute to the success of future projects."';

  useEffect(() => {
    document.title = "PROFILE | BIBASH KATTEL";
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <>
      <div className="heading-profile">
        <div className="fullname" data-aos="fade-down" data-aos-duration="500">
          HI, <br /> I'M <br />
          <span
            className="shadow-effect-header"
            data-aos="fade-down"
            data-aos-duration="800"
          >
            <TypeAnimation
              sequence={["BIBASH", 3000, "KATTEL", 3000]}
              wrapper="span"
              speed={10}
              repeat={Infinity}
            />
          </span>
        </div>
        <div
          className="profile-image-wrapper"
          id="profile-image-wrapper"
          data-aos="fade-down"
          data-aos-duration="800"
        >
          <div className="profile-image">
            <img
              src={profileImage}
              alt="profileimage"
              width={170}
              height={170}
            />
            <ReactTooltip
              anchorId="profile-image-wrapper"
              place="top"
              content="BIBASH    KATTEL"
              delayHide={500}
              style={{
                backgroundColor: "var(--primary-color)",
                color: "#000000",
              }}
            />
          </div>
        </div>
      </div>

      <div
        className="profile-role"
        data-aos="fade-down"
        data-aos-duration="1100"
      >
        {ProfileRole}
      </div>

      <div className="about-me" data-aos="fade-down" data-aos-duration="1400">
        <p> {abouteMe} </p>
      </div>

      <div className="contact-cv" data-aos="fade-up" data-aos-duration="500">
        <a href="/contact" className="btn-contact">
          Contact me !
        </a>
        {/* <a href="" className="btn-cv">
          Download my CV !
        </a>{" "} */}
      </div>
    </>
  );
};

export default Profile;
