import React, { useEffect, useState } from "react";
import "../css/BottomNavBar.css";
import { navBarData } from "../utils/staticData";
import { NavLink } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import VolumeOff from "@mui/icons-material/VolumeOff";
import { SlSettings } from "react-icons/sl";
import Aos from "aos";
import "aos/dist/aos.css";
import { FaMoon, FaVolumeUp } from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";

export const BottomNavBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  let date = new Date().getDate(); //To get the Current Date
  let month = new Date().getMonth() + 1; //To get the Current Month
  let year = new Date().getFullYear(); //To get the Current Year
  let hours = new Date().getHours(); //To get the Current Hours
  let min = new Date().getMinutes(); //To get the Current Minutes
  let [sec, setSecond] = useState(null); //To get the Current Seconds

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    Aos.init();
    Aos.refresh();
    setInterval(() => {
      setSecond(new Date().getSeconds());
    }, 1000);
  }, []);
  return (
    <>
      <div className="container">
        {/* left container */}
        <div className="left-container"></div>
        {/* nav bar container */}
        <div className="nav-bar-container">
          {navBarData.map((data, index) => {
            return (
              <div key={index}>
                <NavLink
                  id={index}
                  className="nav-links"
                  to={data.route}
                  preventScrollReset={false}
                >
                  <span>{data.icon}</span>
                </NavLink>

                <ReactTooltip
                  key={index}
                  anchorId={index}
                  place="top"
                  content={data.linkTitle}
                  style={{
                    backgroundColor: "var(--primary-color)",
                    color: "#000000",
                    fontSize: "12px",
                  }}
                />
              </div>
            );
          })}

          <div
            className="nav-links settings"
            id="setting-positoned-button"
            aria-controls={open ? "setting-positoned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <span>
              <SlSettings />
            </span>

            <ReactTooltip
              anchorId="setting-positoned-button"
              place="top"
              content="Settings"
              style={{
                backgroundColor: "var(--primary-color)",
                color: "#000000",
                fontSize: "12px",
              }}
            />
          </div>

          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <MenuItem onClick={handleClose}>
              <span>
                <VolumeOff />
              </span>
              <span style={{ fontSize: "0.9rem" }}> Turn on nav audio </span>
            </MenuItem>

            <MenuItem onClick={handleClose}>
              <span>
                <FaMoon />
              </span>
              <span style={{ fontSize: "0.9rem" }}> Turn off dark mode </span>
            </MenuItem>
          </Menu>
        </div>

        {/* right container */}
        <div className="right-container">
          <div className="time">{hours + ":" + min + ":" + sec}</div>
          <div className="date"> {date + "/" + month + "/" + year} </div>
        </div>
      </div>
    </>
  );
};

export default BottomNavBar;
